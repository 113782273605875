import { S25Util } from "../../../../util/s25-util";
import { S25Datefilter } from "../../../s25-dateformat/s25.datefilter.service";

/**
 * [field.key] must match rsvdetail data, i.e. [setupDt, preDt, startDt, endDt, postDt, takedownDt]
 */
/**
 * mapping to reservations.xml
 * reservation_start_dt -> setupDt
 * pre_event_dt         -> preDt
 * event_start_dt       -> startDt
 * event_end_dt         -> endDt
 * post_event_dt        -> postDt
 * reservation_end_dt   -> takedownDt
 */
const RSVD_SLIDER_ETALON_RANGES = [
    { styleClass: "range-left", permanent: true },
    { styleClass: "range-setup", field: [{ key: "setupDt", styleClass: "handle-setup-dt" }] },
    { styleClass: "range-pre", field: [{ key: "preDt", styleClass: "handle-pre-dt" }] },
    {
        styleClass: "range-event",
        field: [
            { key: "startDt", styleClass: "handle-start-dt" },
            { key: "endDt", styleClass: "handle-end-dt" },
        ],
        master: true,
    },
    { styleClass: "range-post", field: [{ key: "postDt", styleClass: "handle-post-dt" }] },
    { styleClass: "range-takedown", field: [{ key: "takedownDt", styleClass: "handle-takedown-dt" }] },
    { styleClass: "range-right", permanent: true },
];

export class RsvdSliderService {
    public static composeInstanceRanges(model: any) {
        return RSVD_SLIDER_ETALON_RANGES.map(function (range: any) {
            range = S25Util.clone(range);
            range.field &&
                range.field.map(function (field: any) {
                    field.key && model[field.key] && (range.checked = true);
                });
            return range;
        });
    }

    public static composeModel(etalonRanges: any, model: any, lang: any, dateFormat: string, timeFormat: string) {
        return {
            startDate: S25Util.date.getDate(model.startDt),
            dates: RsvdSliderService._composeDates(etalonRanges, model),
            ranges: RsvdSliderService._composeRanges(etalonRanges),
            barrier: RsvdSliderService._composeBarrier(etalonRanges, model),
            handleLabels: RsvdSliderService._handleLabel.compose(etalonRanges, lang),
            // desired defaultStep in minutes;
            // will be corrected by smartSlider to the maxFittedStep based on modelBean.dates()
            step: 5,
            pref: { step: { selected: 1 } },
            customHandleLabelFormatter: function (startDate: any, date: any) {
                return RsvdSliderServiceFormatUtil.formatTime(startDate, date, dateFormat, timeFormat);
            },
        };
    }

    public static formatDate(date: Date, format: string) {
        return RsvdSliderServiceFormatUtil.formatDate(date, format);
    }

    public static hasBottomLabel(labels: any) {
        return RsvdSliderService._handleLabel.hasLabel(labels, "bottom");
    }

    public static _composeDates(ranges: any, model: any) {
        return ranges.reduce(function (result: any, range: any) {
            range.checked &&
                range.field.map(function (field: any) {
                    model[field.key] && result.push(model[field.key]);
                });
            return result;
        }, []);
    }

    public static _composeRanges(ranges: any) {
        return ranges.reduce(function (result: any, range: any) {
            (range.permanent || range.checked) && result.push({ styleClass: range.styleClass });
            return result;
        }, []);
    }

    public static _composeBarrier(ranges: any[], model: any) {
        // find Min and Max checked dates to be used as barrier values; i.e First might be Setup, Pre-Event, or Event-Start
        var minDate: any = undefined,
            maxDate = undefined;
        ranges.map(function (range) {
            range.checked &&
                range.field.map(function (field: any) {
                    // minDate - first left-most checked field; set once, then skipped
                    if (!minDate && model[field.key]) {
                        minDate = model[field.key];
                        return;
                    }
                    // maxDate - last right-most checked field; re-set at every iteration, last one wins
                    maxDate = model[field.key];
                });
        });
        return {
            minDate: S25Util.date.addDays(minDate, -2),
            maxDate: S25Util.date.addDays(maxDate, 2),
        };
    }

    public static _findMasterIndex(ranges: any[]) {
        var idx = -1;
        ranges.map(function (range, i) {
            range.master && (idx = i);
        });
        return idx;
    }

    public static _handleLabel: any = {
        /**
         *
         * @param etalonRanges
         * @param lang
         * @returns Array: [{title: String, position: String ('top' | 'bottom')}]
         */
        compose: function (ranges: any, lang: any) {
            var labels = RsvdSliderService._handleLabel._composeLabelPositions(ranges);
            return labels.map(function (label: any) {
                label.title = lang[label.key];
                return label;
            });
        },
        hasLabel: function (labels: any, position: any) {
            var result = false;
            labels &&
                labels.map(function (label: any) {
                    label.position == position && (result = true);
                });
            return result;
        },
        _composeLabelPositions: function (ranges: any) {
            var masterIndex = RsvdSliderService._findMasterIndex(ranges);

            // add all for masterIndex - static position 'top'
            var labels = RsvdSliderService._handleLabel._composeSubPositions("top", [ranges[masterIndex]]);

            // switch position top/bottom for each new label; start with 'bottom'
            // left-side labels
            var leftLabels = RsvdSliderService._handleLabel._composeSubPositions(
                "bottom",
                ranges.slice(0, masterIndex).reverse(),
                true,
            );
            S25Util.array.injectArray(labels, 0, leftLabels.reverse());

            // right-side labels
            var rightLabels = RsvdSliderService._handleLabel._composeSubPositions(
                "bottom",
                ranges.slice(masterIndex + 1, ranges.length),
                true,
            );
            S25Util.array.injectArray(labels, labels.length, rightLabels);

            return labels;
        },
        _composeSubPositions: function (position: any, ranges: any, isTogglePosition?: any) {
            return ranges.reduce(function (result: any, range: any) {
                range.checked &&
                    range.field.map(function (field: any) {
                        result.push({ position: position, key: field.key, styleClass: field.styleClass });
                        isTogglePosition && (position = position === "top" ? "bottom" : "top");
                    });
                return result;
            }, []);
        },
    };
}

export class RsvdSliderServiceFormatUtil {
    public static formatDate(date: any, dateFormat: string) {
        return S25Datefilter.transform(date, dateFormat);
    }

    public static formatTime(startDate: any, date: any, dateFormat: string, timeFormat: string) {
        var result = {
            value: RsvdSliderServiceFormatUtil.formatDate(date, timeFormat),
            // no days difference - return an empty String, so previous value will be cleared (if any)
            // 20170509, Mikhail - the difference can be either >0 or <0, i.e. previous day
            overflowDate:
                S25Util.date.diffDays(startDate, date) != 0
                    ? "(" + RsvdSliderServiceFormatUtil.formatDate(date, dateFormat) + ")"
                    : "",
            overflowDateStyleClass: "overflow-date",
        };
        return result;
    }
}

export class RsvdSliderServiceModelUtil {
    public static isExpandable(model: any) {
        // any non-master (i.e. besides [startDt, endDt]) exists
        var result = false;
        RSVD_SLIDER_ETALON_RANGES.map(function (range) {
            !range.master &&
                range.field &&
                range.field.map(function (field) {
                    field.key && model[field.key] && (result = true);
                });
        });
        return result;
    }
}
