import { DropDownItem } from "../../pojo/DropDownItem";
import { OrganizationService } from "../../services/organization.service";
import { SearchService } from "../../services/search/search.service";
import { S25Const } from "../../util/s25-const";
import { S25Util } from "../../util/s25-util";
import { Search } from "../../pojo/Search";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import StepTypes = Search.Location.StepTypes;
import Step = SearchCriteria.Step;
import { S25ItemI } from "../../pojo/S25ItemI";
import { DataAccess } from "../../dataaccess/data.access";

export interface PartitionPrefI {
    groups: PartitionPrefGroupI[]; //only 4 allowed groups
    queryId?: number; //QueryId for partition pref
    [key: string]: any; //allow any other properties
}

export interface PartitionPrefGroupI {
    prefs: DropDownItem[];
    groupId: number;
    orgId?: number;
}

export class S25OrgPartitionPrefsService {
    /**
     *
     * @param partitionPref
     * @param orgIds
     * @param queryId
     * @param preserveSearch - true when you want to reuse an existing search and NOT update the queryId
     */
    public static savePrefs(partitionPref: PartitionPrefI, orgIds: number[], queryId?: number, preserveSearch = false) {
        //preserve search if requested AND queryId is present
        preserveSearch = preserveSearch && queryId > 0;
        let promise: any;
        if (!queryId || preserveSearch) {
            partitionPref.queryId = queryId;
            promise = { queryId: S25OrgPartitionPrefsService.saveAsSearch(partitionPref, !preserveSearch) };
        }

        return S25Util.all(promise).then((resp) => {
            queryId = queryId || S25Util.parseInt(resp.queryId);
            //No need to update org if queryId was preserved
            if (queryId > 0 && !preserveSearch) {
                OrganizationService.updatePartitionPrefs(orgIds, queryId);
            } else if (partitionPref.groups?.length === 0) {
                // unless search is empty, then delete from org
                OrganizationService.updatePartitionPrefs(orgIds, null);
                preserveSearch && SearchService.deleteSearch(queryId, S25Const.itemName2Id.space);
            }
            return queryId;
        });
    }

    //Creates a new search and uses bulk service to do a PUT, old search will be cleaned up via an overnight db proc
    public static async saveAsSearch(partitionPref: PartitionPrefI, isNewSearch = true) {
        if (partitionPref && partitionPref.groups && partitionPref.groups.length === 0) return null; //return null if there is no group

        if (S25OrgPartitionPrefsService.validate(partitionPref)) {
            let searchCriteria = {
                query_id: partitionPref.queryId,
                query_type_name: "preference",
                query_type_id: 3,
                query_method: "all",
                step: S25OrgPartitionPrefsService.groupToStep(partitionPref.groups),
            };

            let origJson = isNewSearch
                ? { query_type_id: 3 }
                : await SearchService.getSearchCriteria(S25Const.itemName2Id.space, partitionPref.queryId);

            return SearchService.putJsonSearch(
                S25Const.itemName2Id.space,
                searchCriteria,
                origJson,
                isNewSearch,
                "org_" + S25Util.generateQuickGUID(),
            ).then((resp: any) => {
                return resp?.queryId;
            });
        } else {
            return -1; //invalid queryId
        }
    }

    public static validate(partitionPref: PartitionPrefI) {
        return partitionPref.groups.every((group) => {
            if (!group.prefs || group.prefs.length < 1) {
                alert("Please remove empty partition groups before saving");
                return false;
            }
            return true;
        });
    }

    public static groupToStep(groups: PartitionPrefGroupI[]) {
        groups = S25Util.array.forceArray(groups);
        let step = groups.map((group, index) => {
            return {
                step_type_id: StepTypes.Partition,
                step_number: index + 1,
                step_type_name: "Partitions",
                step_priority: index + 1, //Used as the group number by optimizer
                qualifier: 1, // required for group number to work properly
                status: "new", // needed to make sure we don't accidentally delete anything on save
                step_param:
                    group.prefs &&
                    group.prefs.map((pref, pIndex) => {
                        return {
                            partition_id: pref.itemId,
                            partition_name: pref.itemName,
                            step_param_nbr: pIndex,
                        };
                    }),
            };
        });
        return step || {};
    }

    public static stepToGroup(step: Step): PartitionPrefGroupI {
        return {
            groupId: Number(step.step_number),
            prefs: S25Util.array.forceArray(step.step_param).map((p: S25ItemI) => {
                return {
                    itemId: p.partition_id || p.itemId,
                    itemName: p.partition_name || p.itemName,
                };
            }),
        };
    }
}
