import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { S25ContactsEditableComponent } from "./s25.org.contacts.editable";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25OrgPartitionPrefsComponent } from "./s25.org.partition.prefs.component";
import { S25ItemModule } from "../s25-item/s25.item.module";

//@author: travis
@NgModule({
    declarations: [S25ContactsEditableComponent, S25OrgPartitionPrefsComponent],
    imports: [CommonModule, S25DropdownPaginatedModule, S25ItemModule],
    exports: [S25ContactsEditableComponent, S25OrgPartitionPrefsComponent],
    providers: [S25ContactsEditableComponent, S25OrgPartitionPrefsComponent],
})
export class S25OrgDetailsModule {
    constructor() {}
}
