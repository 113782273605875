import { S25Util } from "../../../../util/s25-util";
import { RsvdSliderServiceModelUtil } from "./rsrv.slider.service";

// http://knowledge25.collegenet.com/display/SPECS/Functional+Specs+-+Events+Details+-+Occurrences+Pane+Overview
export class RsvdetailServiceUtil {
    public static isRsvExpandable(rsv: any) {
        // 20170214, Mikhail - cancelled reservations are non-expandable
        return (
            !rsv.isCancelled &&
            (RsvdSliderServiceModelUtil.isExpandable(rsv) ||
                (rsv.comment ? true : false) ||
                RsvdetailServiceUtil._isItemListExpandable(rsv.space) ||
                RsvdetailServiceUtil._isItemListExpandable(rsv.spaceApproval) ||
                RsvdetailServiceUtil._isItemListExpandable(rsv.res) ||
                RsvdetailServiceUtil._isItemListExpandable(rsv.resApproval))
        );
    }

    public static isItemExpandable(item: any) {
        // note: item.quantity is shown in collapsed view, no need to expand specifically for item.quantity
        return item.instruction || item.layoutName || item.isShare ? true : false;
    }

    public static _isItemListExpandable(items: any) {
        if (S25Util.array.isEmpty(items)) {
            return false;
        }
        if (items.length > 1) {
            return true;
        }
        return RsvdetailServiceUtil.isItemExpandable(items[0]);
    }
}
